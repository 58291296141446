.menu-open .navbar-light .c-menu-toggler,
.navbar-dark .c-menu-toggler {
  border: 2px solid $white;

  &_inner {
    color: $white;
  }
}

.navbar-light .c-menu-toggler {
  border: 2px solid $primary;

  &_inner {
    color: $primary;
  }
}

.c-menu-toggler {
  $width: 24px;
  $height: 14px;
  $border-height: 2px;
  $speed: 0.25s;
  $easing: ease-in-out;

  position: relative;
  border-radius: 50%;
  padding: 0;
  height: 52px;
  width: 52px;
  background: none;
  z-index: $zIndex-7;

  &_inner {
    position: relative;
    width: $width;
    height: $height;
    background: transparent;
    border-top: $border-height solid;
    border-bottom: $border-height solid;
    border-left: 0;
    border-right: 0;
    font-size: 0;
    transition: all $speed $easing;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: $border-height;
      position: absolute;
      top: 50%;
      left: 50%;
      background: currentColor;
      transform: translate(-50%, -50%);
      transition: transform $speed $easing;
    }
  }

  &[aria-expanded='true'] .c-menu-toggler_inner {
    border-color: transparent;
    transform: scale(-1);

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
