#cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($light, 0.95);
  font-size: 0.875rem;
  z-index: $zIndex-10;

  a {
    color: $dark;
    transition: $transition-base;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: 500;
  }

  .btn {
    font-size: 0.875rem;
    padding: 10px 30px;
  }
}