.widget--image-text {
  .img-wrapper {
    border-radius: $border-radius-lg;
  }
}

.widget--image-text.image-text {
  .img-wrapper {
    @media screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: 1440px) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.widget--image-text.text-image {
  .img-wrapper {
    @media screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: 1440px) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
