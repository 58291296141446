// Color system
$black: #000;
$white: #fff;
$gray-100: mix($black, $white, 10%);
$gray-200: mix($black, $white, 20%);
$gray-300: mix($black, $white, 30%);
$gray-400: mix($black, $white, 40%);
$gray-500: mix($black, $white, 50%);
$gray-600: mix($black, $white, 60%);
$gray-700: mix($black, $white, 70%);
$gray-800: mix($black, $white, 80%);
$gray-900: mix($black, $white, 90%);

$primary: #F94F40;
$secondary: #5F9695;
$tertiary: #F1BC68;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #CCD4D7;
$dark: #2F303F;
$lighter: #fbf5f4;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
      "primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "light": $light,
      "dark": $dark,
      "black": $black,
      "white": $white,
      "lighter": $lighter
    ),
    $theme-colors
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 0.625rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
      0: 0,
      1: $spacer,
      2: $spacer * 2,
      3: $spacer * 3,
      4: $spacer * 4,
      5: $spacer * 5,
      6: $spacer * 6,
      7: $spacer * 7,
      8: $spacer * 8,
      9: $spacer * 9,
      10: $spacer * 10,
      11: $spacer * 11,
      12: $spacer * 12,
      14: $spacer * 14,
      15: $spacer * 15,
      16: $spacer * 16,
    ),
    $spacers
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-600;

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 2rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 40px;
$grid-row-columns: 6;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.6667;
$line-height-sm: 1.6667;

$border-radius: 5px;
$border-radius-lg: 20px;
$border-radius-sm: 3px;

$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow: 0 .5rem 1rem rgba($black, .15);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);

$transition-base: all .2s ease-in-out;
$transition-fade: opacity .15s linear;
$transition-collapse: height .35s ease;

$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
      (
          (21 9),
          (16 9),
          (4 3),
          (3 2),
          (1 1),
      ),
    $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;

$font-size-base: 1.125rem;
$font-size-lg: $font-size-base * 1.16667;
$font-size-sm: $font-size-base * .8889;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$font-weight-base: $font-weight-light;
$line-height-base: 1.6667;

$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2.3334;
$h3-font-size: $font-size-base * 1.7778;
$h4-font-size: $font-size-base * 1.3334;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;

$headings-margin-bottom: $spacer * 3;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 900;
$headings-line-height: 1.3334;
$headings-color: $dark;

$display1-size: $font-size-base * 5;
$display2-size: $font-size-base * 4.5;
$display3-size: $font-size-base * 4;
$display4-size: $font-size-base * 3.5;

$display1-weight: 900;
$display2-weight: 900;
$display3-weight: 900;
$display4-weight: 900;
$display-line-height: 1.1;

$lead-font-size: $font-size-lg;
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-600;

// Navs

$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1rem;

// Navbar

$navbar-padding-y: $spacer * 2;
$navbar-padding-x: 0;

$navbar-nav-link-padding-x: $spacer * 2;

$navbar-brand-font-size: $font-size-base;
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: 0;
$navbar-brand-padding-y: 0;

$navbar-light-color: $dark;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

$navbar-dark-color: $white;
$navbar-dark-hover-color: $primary;
$navbar-dark-active-color: $primary;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-transition: 0.25s ease-in-out;

$btn-padding-y: 1.125rem;
$btn-padding-x: 2.1875rem;
$btn-font-size: $font-size-base;
$btn-line-height: normal;
$btn-white-space: null;

/*$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $btn-line-height;*/

$btn-padding-y-lg: 1.375rem;
$btn-padding-x-lg: 3.125rem;
$btn-font-size-lg: 1.3125rem;
$btn-line-height-lg: $btn-line-height;

$btn-border-width: 0;

$btn-font-weight: $font-weight-bolder;
$btn-box-shadow: 0 0.3px 0.6px rgba($primary, 0.07), 0 0.7px 1.3px rgba($primary, 0.101), 0 1.3px 2.5px rgba($primary, 0.125), 0 2.2px 4.5px rgba($primary, 0.149), 0 4.2px 8.4px rgba($primary, 0.18), 0 10px 20px rgba($primary, 0.25);
$btn-focus-width: 0;
$btn-focus-box-shadow: $btn-box-shadow;
$btn-disabled-opacity: .5;
$btn-active-box-shadow: null;

$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 100px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

$btn-transition: 0.2s ease;

// Forms
$input-padding-y: 0.85rem;
$input-padding-x: 1rem;
$input-font-size: 1rem;
$input-font-weight: 400;

$input-bg: rgba($white, 0.75);
$input-disabled-bg: $gray-200;

$input-color: $dark;
$input-border-color: #e3dcda;
$input-border-width: 1px;
$input-box-shadow: 0;

$input-height: 60px;
$input-border-radius: $border-radius;

$input-focus-bg: $input-bg;
$input-focus-border-color: $primary;
$input-focus-color: $dark;
$input-focus-width: $input-border-width;
$input-focus-box-shadow: 0;

$input-font-weight: $font-weight-normal;
$input-color: $dark;
$input-placeholder-color: #999999;
//$input-plaintext-color: $body-color !default;

$form-grid-gutter-width: 0.625rem;
$form-group-margin-bottom: 0.625rem;
