main#error404 {
  position: relative;

  &::before {
    @extend %coverer;
    @include img-path('404.gif');
    content: '';
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    opacity: 0.25;
    z-index: $zIndex-1;
  }

  > div {
    position: relative;
    z-index: $zIndex-2;
  }
}
