@each $name, $value in $theme-colors {
  .fill-#{$name} {
    fill: $value;
  }

  .stroke-#{$name} {
    stroke: $value;
    fill: none;
  }
}

.bg-gradient-warm-45 {
  background: linear-gradient(45deg, #faf1f0 0%, #fcf7f2 100%);
}

.bg-gradient-warm-n45 {
  background: linear-gradient(-45deg, #faf1f0 0%, #fcf7f2 100%);
}
