



























































@import "~swiper/swiper.scss";
@import "src/scss/abstracts/_variables.scss";
@import "src/scss/vendor/bootstrap/_custom.scss";

.swiper-container:not(.swiper-container-initialized ) {
  opacity: 0;
}

.swiper-gallery {
  overflow: visible;

  /deep/ img {
    transform: skewY(4deg) scale(1.1);
  }

  .swiper-slide {
    box-shadow: 3px 12px 20px rgba($dark, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
}
