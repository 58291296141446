.widget.bg-lighter + .widget--quote,
.widget--quote .widget.bg-lighter,
.widget.bg-lighter + .widget.bg-lighter {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.widget.bg-lighter + .widget--quote {
  background: linear-gradient(45deg, #faf1f0 0%, #fcf7f2 100%);

  .bg-gradient-warm-45 {
    background: none !important;
  }
}

.widget--case-article {
  .display-1 {
    right: 0;
    top: 0;

    @include media-breakpoint-up(md) {
      position: absolute;
    }
  }
}

.widget--quote {
  .bg-cover {
    transform: skewY(4deg) scale(1.15);
    opacity: 0.44;
  }

  .text-white .icon-quote {
    opacity: 0.12;
  }
}

.widget--image-rows {
  padding-top: 10vw;

  @include media-breakpoint-up(lg) {
    padding-top: 8vw;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 6vw;
  }

  .swiper-container .swiper-slide {
    width: 33.33vw;

    @include media-breakpoint-up(lg) {
      width: 20vw;
    }

    @include media-breakpoint-up(xl) {
      width: 16.66vw;
    }
  }
}
