.c-case {
  $icon-width: 12px;

  .logo-wrapper {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    img {
      height: 160px;
      width: 160px;
      object-fit: contain;
      transform: skewY(4deg) scale(1);
    }
  }

  .img-wrapper:hover .case-image {
    transform: skewY(4deg) scale(1.25);
  }

  .case-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    transition: 0.25s ease-in-out;
  }

  .icon-arrow {
    width: $icon-width;
    height: 9px;
  }

  .case-link {
    position: relative;
    padding-right: $icon-width + 10px;
    overflow: hidden;

    @keyframes arrow-move-in {
      0% {
        transform: translateY(-50%);
        right: 0;
      }
      49% {
        transform: translateX(100%) translateY(-50%);
        right: 0;
      }
      50% {
        opacity: 0;
      }
      51% {
        left: 0;
        right: auto;
        transform: translateX(-100%) translateY(-50%);
        opacity: 0;
      }
      100% {
        left: 0;
        right: auto;
        transform: translateY(-50%);
        opacity: 1;
      }
    }

    &:hover {
      padding-left: $icon-width + 10px;

      .icon {
        animation: arrow-move-in 0.2s ease-in-out forwards;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}
