main#clients-overview {
  .heading-columns-wrapper {
    padding-top: 100px;
    position: relative;

    @include media-breakpoint-up(sm) {
      padding-top: 142px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(75% + 30rem);
      transform: skewY(-4deg) scale(1.25);
      background: linear-gradient(-45deg, #faf1f0 0%, #fcf7f2 100%);
    }
  }
}
