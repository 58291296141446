/**
 * Basic typography style for copy text
 */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @include media-breakpoint-down(sm) {
    margin-bottom: $grid-gutter-width * 0.5;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  text-rendering: optimizeLegibility;
}

h1, .h1 {
  font-size: responsive ($h1-font-size * 0.65) $h1-font-size;
  font-range: map-get($grid-breakpoints, md) map-get($grid-breakpoints, xxl);
}

h2, .h2 {
  font-size: responsive ($h2-font-size * 0.7) $h2-font-size;
  font-range: map-get($grid-breakpoints, md) map-get($grid-breakpoints, xxl);
}

h3, .h3 {
  font-size: responsive ($h3-font-size * 0.75) $h3-font-size;
  font-range: map-get($grid-breakpoints, md) map-get($grid-breakpoints, xxl);
}

h4, .h4 {
  font-size: responsive ($h4-font-size * 0.75) $h4-font-size;
  font-range: map-get($grid-breakpoints, md) map-get($grid-breakpoints, xxl);
}

h5, .h5 {
  font-size: responsive ($h5-font-size * 0.8) $h5-font-size;
  font-range: map-get($grid-breakpoints, md) map-get($grid-breakpoints, xxl);
}

.display-1 {
  font-size: responsive ($display1-size * 0.6) $display1-size;
  font-range: map-get($grid-breakpoints, md) map-get($grid-breakpoints, xxl);
}

.display-4 {
  font-size: responsive ($display4-size * 0.65) $display4-size;
  font-range: map-get($grid-breakpoints, md) map-get($grid-breakpoints, xxl);
}

.text-16 {
  font-size: rem-calc(16px);
}

.text-14 {
  font-size: rem-calc(14px);
}

.text-13 {
  font-size: rem-calc(13px);
}

.text-sm {
  font-size: $font-size-sm;
}

.text-underline {
  text-decoration: underline;
}

.text-extra-light {
  color: #E7E8E9;
}

.cms-text {
  h2 {
    margin-top: $grid-gutter-width * 0.75;
    margin-bottom: $grid-gutter-width * 0.5;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}
