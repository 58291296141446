// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
}

.btn-primary:not(.btn-lg):not(.btn-sm){
  box-shadow: 0 6px 12px rgba($primary, 0.25);

  &:hover {
    box-shadow: 0 2px 8px rgba($primary, 0.4);
  }
}

.btn-lg.btn-primary {
  box-shadow: 0 10px 20px rgba($primary, 0.25);

  &:hover {
    box-shadow: 0 4px 12px rgba($primary, 0.4);
  }
}

.btn-reset {
  all: unset;
}
