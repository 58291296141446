.menu-open #page-header {
  .navbar-dark,
  .navbar-light {
    @include media-breakpoint-down($menu-bp-down) {
      .nav-link {
        color: $white;
      }

      .active .nav-link {
        opacity: 0.7;

        &.btn-primary {
          border-color: rgba($white, 0.7);
        }
      }

      .nav-link.btn:not(.btn-primary) {
        border-color: $white;
      }

      .navbar-brand .icon {
        fill: $white;
      }
    }
  }
}

#page-header {
  $nav-link-height: 52px;

  position: relative;
  z-index: $zIndex-5;

  .navbar-brand {
    @include media-breakpoint-down($menu-bp-down) {
      order: 1;
    }
  }

  .c-menu-toggler {
    @include media-breakpoint-down($menu-bp-down) {
      order: 3;
    }
  }

  .navbar-dark .nav-link.btn:not(.btn-primary) {
    border-color: $white;
  }

  .navbar-light {
    .nav-link.btn:not(.btn-primary) {
      border-color: $dark;
    }

    .dropdown:not(.show) a:not(:active):not(:focus):not(:hover) .icon {
      fill: $dark;
    }
  }

  .icon-brand-logo {
    width: 66px;
    height: 60px;

    @include media-breakpoint-up($menu-bp-up) {
      width: 79px;
      height: 72px;
    }
  }

  .navbar {
    @include media-breakpoint-up($menu-bp-up) {
      padding-top: ($spacer * 3);
      padding-bottom: ($spacer * 3);
    }
  }

  .nav-link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;

    @include media-breakpoint-up($menu-bp-up) {
      height: $nav-link-height;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include media-breakpoint-only(md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &.btn {
      border-width: 2px;
      border-radius: $nav-link-height;

      @include media-breakpoint-down($menu-bp-down) {
        margin-top: $spacer;
        padding: 0.65rem 1.25rem;
      }

      @include media-breakpoint-only(md) {
        margin-left: 1rem;
      }

      @include media-breakpoint-up(lg) {
        margin-left: $navbar-nav-link-padding-x;
      }

      &:not(.btn-primary) {
        border-style: solid;

        &:hover,
        &:focus {
          border-color: rgba($white, 0.7);

          @include media-breakpoint-up($menu-bp-up) {
            border-color: $navbar-light-hover-color;
          }
        }
      }
    }
  }

  .nav-item.active .btn {
    color: $white;
  }

  .nav-languages {
    $icon-size: 10px;
    $padding-x: $spacer * 2;

    margin-left: $padding-x;
    margin-right: -$padding-x;

    @include media-breakpoint-only(md) {
      margin-left: 1rem;
      margin-right: -1rem;
    }

    @include media-breakpoint-down($menu-bp-down) {
      order: 2;
      margin-left: auto !important;
      margin-right: $padding-x;
    }

    .nav-link {
      outline: none;

      @include media-breakpoint-only(md) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      @include media-breakpoint-down($menu-bp-down) {
        padding-left: $padding-x;
      }
    }

    .show .icon,
    a:active .icon,
    a:focus .icon,
    a:hover .icon {
      fill: $link-color;
    }

    .show .icon {
      transform: scaleY(-1);
    }

    .icon {
      margin-left: 0.5rem;
      width: $icon-size;
      height: $icon-size;
    }

    .dropdown-menu {
      position: absolute;
      min-width: 0;
      border-radius: 4px;
      overflow: hidden;
      left: -5px;
      transform: translateY(-8px);
      box-shadow: 0 6px 12px rgba($black, 0.12);

      @include media-breakpoint-down($menu-bp-down) {
        transform: translateY(-2px);
      }

      a {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
}

#menu {
  @include media-breakpoint-up($menu-bp-up) {
    .nav-item {
      transform: none !important;
      opacity: 1 !important;
    }
  }

  @include media-breakpoint-down($menu-bp-down) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 120px 60px;
    text-align: center;
    background: $primary;
    z-index: $zIndex-6;
    overflow: hidden;

    ul {
      height: 100%;
      overflow: auto;
    }

    .nav-link {
      @include font-size(20px);
      justify-content: center;

      @include media-breakpoint-down($menu-bp-down) {
        color: $white;
      }

      &:focus,
      &:hover {
        color: $white;
        opacity: 0.7;
      }
    }

    .nav-item + .nav-item {
      @include media-breakpoint-down($menu-bp-down) {
        margin-top: $spacer;
      }
    }

    .menu-open & {
      display: block;
    }
  }
}
