.widget--article {
  .flip-order {
    @include media-breakpoint-only(xs) {
      > div:first-child {
        order: 2;
      }

      > div:last-child {
        order: 1;
      }
    }
  }

  .flex-column-reverse {
    h2 {
      order: 2;
    }

    .cms-text {
      order: 1;
    }

    .img-wrapper {
      order: 3;
    }
  }

  .parallax {
    @include media-breakpoint-only(xs) {
      transform: none !important;
    }
  }
}
