.widget--image {
  padding-top: 5vh;
  padding-bottom: 5vh;

  img {
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-up(md){
      margin-top: -10vh;
    }

    @media screen and (min-height: 480px) {
      max-height: 100vh;
    }
  }

  .img-wrapper {
    @media screen and (min-height: 600px) {
      max-height: 75vh;
    }
  }

  .mw-70.ml-0 .img-wrapper {
    @media screen and (min-width: $max-width * 6) and (max-width: $max-width * 9) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .mw-70.mr-0 .img-wrapper {
    @media screen and (min-width: $max-width * 6) and (max-width: $max-width * 9) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .mw-60 .img-wrapper {
    @media screen and (max-width: $max-width * 6) {
      border-radius: 0 !important;
    }
  }

  .mw-70 .img-wrapper {
    @media screen and (max-width: $max-width * 7) {
      border-radius: 0 !important;
    }
  }

  .mw-max .img-wrapper {
    @media screen and (min-width: $cf-max-width) {
      border-radius: $border-radius-lg;
    }
  }
}
