// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
* {
  @include selection($body-bg, $primary);
  @include font-smoothing(on);

  &:focus {
    outline-color: $link-hover-color !important;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* apply a natural box layout model to all elements, but allowing components to change */
  box-sizing: border-box;
  position: relative;
  min-height: 100%;

  &.menu-open {
    @include media-breakpoint-down($menu-bp-down) {
      overflow: hidden;
    }
  }
}

a {
  transition: $transition-base;
}

.container-fluid {
  padding-left: $grid-gutter-width * 0.5;
  padding-right: $grid-gutter-width * 0.5;
  max-width: $cf-max-width;

  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  @include media-breakpoint-up(lg) {
    padding-left: $grid-gutter-width * 1.5;
    padding-right: $grid-gutter-width * 1.5;
  }

  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width * 2.5;
    padding-right: $grid-gutter-width * 2.5;
  }

  .row-custom {
    margin-left: -($grid-gutter-width * 0.25);
    margin-right: -($grid-gutter-width * 0.25);
  }

  .col-custom {
    padding-left: $grid-gutter-width * 0.25;
    padding-right: $grid-gutter-width * 0.25;
  }
}

.swiper-wrapper{
  will-change: transform;
}
