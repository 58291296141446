.custom-checkbox {
  $checkbox-size: 24px;

  .custom-control-label {
    cursor: pointer;
    padding-left: $input-padding-x * 0.75;

    @include font-size(16px);
  }

  .custom-control-label::before,
  .custom-control-label::after {
    top: 0;
    width: $checkbox-size;
    height: $checkbox-size;
  }

  .custom-control-label::before {
    border: 1px solid $input-border-color;
  }

  .custom-control-input:active ~ .custom-control-label::before {
    background: $input-focus-border-color;
    border-color: $input-focus-border-color;
  }
}
