textarea{
  min-height: $input-height * 2;
}

.form-control {
  &::placeholder {
    font-weight: $font-weight-light;
    font-style: italic;
  }

  &.is-invalid {
    background-image: none;

    ~ .invalid-feedback {
      display: block !important;
    }
  }
}

.float-label {
  position: relative;

  label {
    @include font-size(10px);

    position: absolute;
    top: 0;
    left: $input-padding-x;
    transform: translateY(10px);
    opacity: 0;
    font-weight: 600;
    color: $input-placeholder-color;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.2s ease-in;
  }

  input:not(:placeholder-shown) {
    padding-top: 16px;
    padding-bottom: 0;
  }

  textarea:not(:placeholder-shown) {
    padding-top: 22px;
    padding-bottom: 0;
  }

  .form-control:placeholder-shown + label {
    // visibility: hidden;
    pointer-events: none;
    touch-action: none;
    user-select: none;
  }

  .form-control:not(:placeholder-shown) + label {
    transform: translateY(10px);
    opacity: 1;
    transition-timing-function: ease-out;
  }
}

.custom-control-input.is-invalid ~ .custom-control-label a {
  color: $danger;
}

.custom-control-input ~ .invalid-feedback {
  padding-left: $input-padding-x;
}

.invalid-feedback {
  font-size: 75%;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 15px;
}
