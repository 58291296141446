main#home {
  .fs-video {
    position: relative;
    width: 100%;
    min-height: 100vh;
    // min-height: stretch;
    left: 0;
    top: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 33%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
      z-index: $zIndex-2;
    }

    .cover-visual {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $zIndex-1;
      opacity: 0.75;
    }

    .content-wrapper {
      position: relative;
      padding-top: 116px;
      padding-bottom: 136px;
      z-index: $zIndex-3;

      @include media-breakpoint-up(md) {
        padding-top: 146px;
        padding-bottom: 166px;
      }

      @include media-breakpoint-up(md) {
        padding-top: 176px;
        padding-bottom: 196px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 206px;
        padding-bottom: 226px;
      }

      @include media-breakpoint-up(xl) {
        padding-top: 236px;
        padding-bottom: 256px;
      }
    }

    .svg-underline {
      background: url('/img/underline.svg') no-repeat 0 90%;
      background-size: 100% 22px;
    }

    .btn-play {
      $size: 86px;

      position: relative;
      width: $size;
      height: $size;
      border-radius: 50%;
      backface-visibility: hidden;
      transition: $transition-base;

      &:hover {
        transform: scale(1.1);

        .ripple {
          border-color: $link-hover-color;
        }
      }

      .ripple {
        display: inline-block;
        border: 2px solid $primary;
        border-radius: 50%;
        transition: $transition-base;
        animation: ripple 5s linear infinite;
        will-change: transform, opacity;
        @extend %coverer;

        &--2 {
          animation-delay: 1.1s;
        }

        &--3 {
          animation-delay: 2.2s;
        }

        @keyframes ripple {
          0% {
            transform: scale(1) translateZ(0);
            opacity: 1;
          }
          25% {
            opacity: 0.5;
          }
          50%,
          100% {
            transform: scale(1.6) translateZ(0);
            opacity: 0;
          }
        }
      }
    }

    .icon-play {
      position: relative;
      right: -2px;
      width: 22px;
      height: 24px;
    }
  }

  .svg-curve-center {
    position: absolute;
    height: 30px;
    bottom: -1px;
    left: 0;
    right: 0;
    z-index: $zIndex-4;

    @include media-breakpoint-up(md) {
      height: 40px;
    }

    @include media-breakpoint-up(lg) {
      height: 60px;
    }
  }

  .widget--step:last-of-type {
    margin-bottom: 0 !important;
  }
}
