




































@import "src/scss/abstracts/_functions.scss";
@import "src/scss/abstracts/_variables.scss";
@import "src/scss/vendor/bootstrap/_custom.scss";

.c-scroll-down {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: $font-weight-bolder;
  font-size: rem-calc(13px);
  letter-spacing: 1px;
  z-index: $zIndex-2;

  &,
  &:hover {
    color: $white;
    outline: none;
  }

  &:hover {
    opacity: 0.75;
  }

  .icon {
    display: block;
    width: 15px;
    height: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}
