main#contact {
  .form-wrapper {
    margin-top: -8vh;
    padding-top: 8vh;
  }

  .submit-wrapper,
  .submit-wrapper .btn {
    @include media-breakpoint-only(xs) {
      width: 100%;
    }
  }
}
