.widget--quote {
  padding-top: 4vw;
  padding-bottom: 4vw;

  .icon-quote {
    top: 0;
    right: 0;
    width: 100px;
    height: 86px;
    z-index: -1;
    transform: translateY(-50%);

    @include media-breakpoint-up(sm){
      width: 120px;
      height: 104px;
    }

    @include media-breakpoint-up(lg){
      width: 143px;
      height: 123px;
    }
  }
}
