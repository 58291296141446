/**
* Calculates font size in rem
* Parameter ($size): font size in pixels
*/
@function rem-calc($size) {
  // Browser default is 16px
  // (!) Note set to 10 because Bootstrap scaffolding sets the html font-size to 10px
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
