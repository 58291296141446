.icon,
.icon-container {
  display: inline-block;
  vertical-align: middle;
  transition: $transition-base;
}

.icon {
  &.shape-chevron {
    &.chevron-left {
      transform: rotate(90deg);
    }

    &.chevron-right {
      transform: rotate(-90deg);
    }

    &.chevron-top {
      transform: rotate(180deg);
    }
  }

  &.shape-arrow {
    &.arrow-left {
      transform: rotate(90deg);
    }

    &.arrow-right {
      transform: rotate(-90deg);
    }

    &.arrow-top {
      transform: rotate(180deg);
    }
  }
}
