$prefixes: '-webkit-', '-moz-', '-o-', '';

/*
* Set a rem font size with pixel fallback
* @Usage @include font-size(14px);
*/
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/*
* Turn font anti aliasing on or off in Chrome / FF
* @Usage @include font-smoothing(on);
* @Usage on black / dark backgrounds @include font-smoothing(off);
*/
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/*
* Outputs vendor-prefixed placeholders for styling. Must be nested in a rule-set.
* @usage:
    input {
      @include placeholder {
        color: red;
      }
    }
*/
@mixin placeholder {
  $placeholders: ':-webkit-input' ':-moz' '-moz' '-ms-input';
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

/*
* Sets selection colors
* @Usage @include selection(#fff, #000);
*/
@mixin selection($color, $background) {
  @each $prefix in $prefixes {
    ::#{$prefix}selection {
      background: $background;
      color: $color;
    }
  }
}

@mixin img-path($path) {
  background-image: url('../../../cms/web/img/' + $path);
}
