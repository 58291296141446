// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
#site-footer {
  li > a {
    color: $gray-300;

    &:hover,
    &:focus {
      color: $link-color;
    }
  }

  .footer-cta {
    max-width: 100vw;
    // overflow: hidden; // this removes top border-radius

    .content-wrapper {
      border-radius: $border-radius-lg;
      // border-bottom-left-radius: $border-radius-lg;
      // border-bottom-right-radius: $border-radius-lg;
    }
  }

  .footer-meta {
    z-index: $zIndex-2;
    color: $gray-300;
  }

  .swiper-gallery .swiper-slide {
    @include media-breakpoint-down(lg) {
      width: 20vw !important;
    }

    @include media-breakpoint-down(sm) {
      width: 25vw !important;
    }

    @include media-breakpoint-only(xs) {
      width: 33.33vw !important;
    }
  }

  .logo-gw {
    transition: $transition-base;

    &:not(:hover) {
      opacity: 0.6;
    }
  }
}
