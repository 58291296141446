.l-employee,
.l-employee-card {
  h3 small,
  .h3 small {
    text-transform: uppercase;
    letter-spacing: rem-calc(1.5px);
    font-weight: 600;
    color: $gray-400;
    @include font-size(12px);
  }

  /*.img-wrapper {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }*/

  img {
    @include media-breakpoint-only(xs) {
      max-height: 65vh;
      object-fit: cover;
      object-position: top;
    }
  }
}

.l-employee {
  .text-wrapper {
    flex: 1;
  }

  .img-wrapper {
    @include media-breakpoint-up(sm) {
      max-width: 200px;
      flex-basis: 200px;
    }

    @include media-breakpoint-up(md) {
      max-width: 300px;
      flex-basis: 300px;
    }
  }
}
