// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
$zIndex-base: 1;
$zIndex-1: $zIndex-base * 10;
$zIndex-2: $zIndex-base * 200;
$zIndex-3: $zIndex-base * 300;
$zIndex-4: $zIndex-base * 400;
$zIndex-5: $zIndex-base * 500;
$zIndex-6: $zIndex-base * 600;
$zIndex-7: $zIndex-base * 700;
$zIndex-8: $zIndex-base * 800;
$zIndex-9: $zIndex-base * 900;
$zIndex-10: $zIndex-base * 999;

$menu-bp-down: sm;
$menu-bp-up: md;

$cf-max-width: 2000px;

$imgPath: ''
