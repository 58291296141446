.widget--step {
  img {
    @include media-breakpoint-only(xs) {
      max-height: 65vh;
      object-fit: cover;
      object-position: top;
    }
  }

  .row {
    position: relative;
  }

  .col-image {
    z-index: $zIndex-2;

    @include media-breakpoint-up(sm) {
      position: absolute;
    }
  }

  .col-content {
    @include media-breakpoint-down(sm) {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  &:not(.reversed) {
    .col-image {
      @include media-breakpoint-up(sm) {
        left: 0;
      }
    }

    .img-wrapper {
      @include media-breakpoint-up(sm) {
        box-shadow: 15px 15px 0 0 $white;
      }
    }
  }

  &.reversed {
    .col-image {
      @include media-breakpoint-up(sm) {
        right: 0;
      }
    }

    .col-content > div {
      @include media-breakpoint-up(sm) {
        margin-right: calc(100% / #{$grid-columns});
      }
    }

    .img-wrapper {
      @include media-breakpoint-up(sm) {
        box-shadow: -15px 15px 0 0 $white;
      }
    }
  }

  [data-parallax] {
    @include media-breakpoint-only(xs) {
      transform: none !important;
    }
  }
}
