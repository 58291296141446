// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.of-cover {
  object-fit: cover;
}

.ts-dark-2 {
  text-shadow: 2px 2px 0 $dark;
}

.ts-dark-1 {
  text-shadow: 1px 1px 0 $dark;
}

.ts-white-1 {
  text-shadow: 1px 1px 0 $white;
}

.ts-white-2 {
  text-shadow: 2px 2px 0 $white;
}

.skew-y-n4 {
  transform: skewY(-4deg);
}

.skew-y-4 {
  transform: skewY(4deg);
}

.unskew-img {
  transform: skewY(4deg) scale(1.125);
}

.z-1 {
  z-index: $zIndex-1;
}

.z-2 {
  z-index: $zIndex-2;
}

.z-3 {
  z-index: $zIndex-3;
}

.z-7 {
  z-index: $zIndex-7;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.bxs-md {
  box-shadow: 3px 12px 20px rgba($dark, 0.1);
}

.w-100vw {
  width: 100vw;
}

.min-100-vh {
  min-height: 100vh;
}

.bg-cover {
  background-size: cover;
}

.bg-img-center {
  background-position: 50% 50%;
}

.line-height-base {
  line-height: $line-height-base;
}
