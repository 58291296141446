main#clients-entry {
  .bg-cover {
    padding-bottom: 100px;
    padding-top: 100px;
    min-height: 100vh;

    @include media-breakpoint-up(sm) {
      padding-top: 142px;
      padding-bottom: 162px;
    }
  }

  .bg-cover.position-relative::before {
    content: '';
    background: rgba($dark, 0.66);
    z-index: $zIndex-1;
    @extend %coverer;
  }

  .img-logo {
    @include media-breakpoint-down(md) {
      max-width: 120px;
    }
  }
}
