$max-width: 10rem;
$max-widths: ();
// stylelint-disable-next-line scss/dollar-variable-default
$max-widths: map-merge(
    (
      10: $max-width,
      20: $max-width * 2,
      30: $max-width * 3,
      40: $max-width * 4,
      50: $max-width * 5,
      60: $max-width * 6,
      70: $max-width * 7,
      75: $max-width * 7.5,
      80: $max-width * 8,
      90: $max-width * 9,
      100: $max-width * 10,
    ),
    $max-widths
);

@each $name, $value in $max-widths {
  .mw-#{$name} {
    max-width: $value;
    margin-left: auto;
    margin-right: auto;
  }
}

.mw-max {
  margin-left: auto;
  margin-right: auto;
  max-width: $cf-max-width;
}

.mw-100vw {
  max-width: 100vw;
}
